import React, { useEffect, useRef, useState } from 'react'
import { logo } from '../../Assets/images';
import BASEURL from '../../Config/global';
import { PreviewPauseButton, PreviewPlayButton, crossIcon } from '../../Assets/svg';
import './style.css';
import { Howl, Howler } from "howler";
import axios from 'axios';
import { useLocation, useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import BASEURLFrontend from '../../Config/urls';
import { getAccessToken } from '../../Util/authHeader';
import { decryptUserId } from '../../Services/Common';
import { Spinner } from 'react-bootstrap';

const SaveShareMixExternal = () => {
    const navigate = useNavigate();
    let location = useLocation();
    let { id } = useParams();
    let token = getAccessToken();

    const [mixerData, setMixerData] = useState([]);
    const [rangeValue, setRangeValue] = useState(50);
    const [audioData, setAudioData] = useState([]);
    const [soundData, setSoundData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [timeLeft, setTimeLeft] = useState(120);
    const [isRunning, setIsRunning] = useState(false);



    useEffect(() => {
        if (token && id) {
            const originalPath = location.pathname;
            const updatedPath = originalPath.replace("/share.mix", "/home");
            navigate(updatedPath, { replace: true });
            console.log(id, location, 'Sign user')
        } else {
            if (id) {
                getDataByApi();
                console.log(id, location, 'No Sign user')
            }
        }
    }, [token]);


    const getDataByApi = async () => {
        try {
            const userId = decryptUserId(id);
            const response = await axios.get(`${BASEURL}/api/sounds/sounds_mixture/${userId}`);
            setMixerData(response.data.data[0]['sounds_list']);
            setLoading(false)
        } catch (error) {
            console.error(error);
            setLoading(false)
            navigate("/signup");
        }
    }

    useEffect(() => {
        let audios = []
        let sounds = []
        mixerData && mixerData.filter((item, index) => {
            if (item.type != "sounds") {
                audios.push(item)
            } else {
                sounds.push(item)
            }
        })
        setAudioData(audios);
        setSoundData(sounds);
    }, [mixerData])

    useEffect(() => {
        if (!isRunning) {
            pauseSound();
            return; // If not running, exit the function early
        } else {
            playSound();
        }; // If not running, skip the timer logic

        const timer = setInterval(() => {
            setTimeLeft((prevTime) => {
                if (prevTime === 0) {
                    setIsRunning(false);
                    pauseSound();
                    return 120; // Reset to 120 once it reaches 0
                }
                return prevTime - 1;
            });
        }, 1000);

        return () => clearInterval(timer); // Clean up timer on component unmount
    }, [isRunning]);

    const toggleTimer = () => {
        setIsRunning((prev) => !prev); // Toggle between pause and resume
    };

    const pauseSound = () => {
        mixerData.forEach((item, i) => {
            document.getElementById(`audio${i}`).pause();
        })
    }

    const playSound = () => {
        mixerData.forEach((item, i) => {
            document.getElementById(`audio${i}`).play();
        })
    }

    if (loading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100vh' }}>
                <Spinner animation="border" role="status" className='text-white'>
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        )
    }

    return (
        <div style={{ height: '100vh' }}>
            <div style={{ width: '85%' }} className='h-100 m-auto d-flex flex-column share-mix-external-layout'>
                <div className="logo d-flex align-items-center justify-content-center pt-5">
                    <img src={logo} alt="Logo" style={{ width: '38%' }} />
                </div>
                <div className='d-flex flex-column justify-content-center'>
                    <div className='text-center my-5'>
                        {/* <h4 className='text-uppercase fw-light' style={{ fontSize: '19px' }}>Welcome!</h4> */}
                        <p className='fw-light fs-5 m-auto px-4' style={{ lineHeight: '1.5' }}>Enjoy this Mix Sample, shared with you.</p>
                    </div>
                    {/* <div className='d-flex justify-content-center mb-4'>
                        <button style={{ backgroundColor: '#a4a2b0' }} className='w-50 px-4 py-2 fs-6 fw-light text-white text-capitalize btn rounded-pill d-flex align-items-center justify-content-center flex-shrink-0' onClick={startsound}>
                            <img src={PreviewPlayButton} style={{ width: '11px' }} />
                            <span className='ps-2'>{!timer ? 'preview' : timer}</span>
                        </button>
                    </div> */}
                    <div className='share-mix-sound-pallete'>
                        <h6 className='title fw-light mb-2'>Sounds</h6>
                        <div className='external-mixer-pallete'>
                            {
                                soundData && soundData.map((sound, index) => (
                                    <div className="mixerSoundDetail py-2" key={index}>
                                        <div className="mixerSoundThumbnailWrapper flex-shrink-0">

                                            <div className="mixerSoundThumbnail mixerStyle">
                                                <img
                                                    src={sound.tumbnail}
                                                    alt="Thumbnail"
                                                />
                                            </div>
                                        </div>
                                        <div className="flex-grow-1">
                                            <p className="mixerAudioTitle m-0">
                                                {sound.name}
                                            </p>
                                            <div>
                                                {/* <ReactAudioPlayer
                                                            ref={(r) => { el[index] = r; }}
                                                            src={BASEURL + item.ios_patch}
                                                            controls
                                                            autoPlay={false}
                                                            loop={true}
                                                            onListen={(e) => listen(e, index)}
                                                            listenInterval={10}
                                                            onCanPlay={e => isReadyToPlay(e, index)}
                                                        /> */}
                                                <div className='d-none'>
                                                    <audio src={sound?.sounds_detail?.[0]?.audio_mobile ? sound?.sounds_detail?.[0]?.audio_mobile : sound?.sounds_detail?.[0]?.ios_sound} id={`audio${index}`} controls />
                                                </div>
                                            </div>
                                            <div className="range d-none">
                                                <input type="range" id="Range1" value={rangeValue} onChange={(e) => setRangeValue(e.currentTarget)} />
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                        {
                            (audioData && audioData.length > 0) && <div>
                                < h6 className='title fw-light text-capitalize my-2'>{audioData[0].type}</h6>
                                <div className=''>
                                    <div className="mixerSoundDetail py-2">
                                        <div className="mixerSoundThumbnailWrapper flex-shrink-0">

                                            <div className="mixerSoundThumbnail mixerStyle">
                                                <img
                                                    src={audioData[0].tumbnail}
                                                    alt="Thumbnail"
                                                />
                                            </div>
                                        </div>
                                        <div className="flex-grow-1">
                                            <p className="mixerAudioTitle m-0">
                                                {audioData[0].name}
                                            </p>
                                            <div>
                                                <div className='d-none'>
                                                    <audio src={audioData[0].audio} id={`audio${mixerData && mixerData.length - 1}`} controls />
                                                </div>
                                            </div>
                                            <div className="range d-none">
                                                <input type="range" id="Range1" value={rangeValue} onChange={(e) => setRangeValue(e.currentTarget)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        }
                    </div>
                    <div className='d-flex justify-content-between gap-4 mt-5 mb-5'>
                        <button style={{ backgroundColor: '#a4a2b0' }} className='w-50 px-4 py-2 fs-6 fw-light text-white text-capitalize btn rounded-pill d-flex align-items-center justify-content-center flex-shrink-0 mx-auto' onClick={toggleTimer}>
                            <img src={isRunning ? PreviewPauseButton : PreviewPlayButton} style={{ width: '11px' }} />
                            <span className='ps-2' style={{ width: '50px' }}>{(!isRunning && timeLeft === 120) ? 'listen' : timeLeft}</span>
                        </button>
                        {/* <button style={{ backgroundColor: '#ffffff1f' }} className='w-50 px-4 py-2 fs-6 fw-light text-white text-capitalize btn rounded-pill d-flex align-items-center justify-content-center flex-grow-1'>
                            <span className='ps-2'>Play in App</span>
                        </button> */}
                    </div>
                </div>
                <div className='text-center mb-5 externalLink justify-content-center'>
                    <Link to={`${BASEURLFrontend}/signup`} className=" ">
                        Create Account
                    </Link>
                    or
                    <Link to={`${BASEURLFrontend}/login`} className="">
                        Login
                    </Link>
                </div>
                <div className='text-center mb-2 opacity-50 pt-5'>
                    <small className='fw-light'>© 2020-2024 Scofa, LLC</small>
                </div>
            </div>
        </div >
    )
}

export default SaveShareMixExternal;